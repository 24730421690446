@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  scroll-behavior: smooth !important;
}

@font-face {
  font-family: 'Gilroy_Light';
  src: url('assets/fonts/Gilroy-Light.ttf');
}
@font-face {
  font-family: 'Gilroy_Regular';
  src: url('assets/fonts/Gilroy-Regular.woff');
}
@font-face {
  font-family: 'Gilroy_Medium';
  src: url('assets/fonts/Gilroy-Medium.ttf');
}
@font-face {
  font-family: 'Gilroy_Semibold';
  src: url('assets/fonts/Gilroy-SemiBold.ttf');
}
@font-face {
  font-family: 'Gilroy_Bold';
  src: url('assets/fonts/Gilroy-Bold.ttf');
}
@font-face {
  font-family: 'Gilroy_Black';
  src: url('assets/fonts/Gilroy-Black.ttf');
}

@layer components {
  .d {
    @apply border-2 border-red-900 border-solid;
  }

  .blade-top-padding {
    @apply pt-8 md:pt-10 lg:pt-12  xl:pt-16 2xl:pt-20  /* needs to be changed*/;
  }
  .blade-top-padding-sm {
    @apply pt-4  md:pt-6  lg:pt-8 xl:pt-10 2xl:pt-14;
  }
  .blade-top-padding-lg {
    @apply pt-12 md:pt-16 lg:pt-20 xl:pt-24 2xl:pt-32;
  }

  .blade-bottom-padding {
    @apply pb-8 md:pb-10 lg:pb-12  xl:pb-16 2xl:pb-20 /* needs to be changed*/;
  }
  .blade-bottom-padding-sm {
    @apply pb-4  md:pb-6  lg:pb-8 xl:pb-10 2xl:pb-14/* needs to be changed*/;
  }
  .blade-bottom-padding-lg {
    @apply pb-12 md:pb-16  lg:pb-20 xl:pb-24 2xl:pb-32; /* needs to be changed*/
  }

  .blade-top-margin {
    @apply mt-8 md:mt-10 lg:mt-12  xl:mt-16 2xl:mt-20;
  }
  .blade-top-margin-sm {
    @apply mt-4  md:mt-6  lg:mt-8 xl:mt-10  2xl:mt-14;
  }
  .blade-top-margin-lg {
    @apply mt-10 md:mt-12  lg:mt-16 xl:mt-20 2xl:mt-24;
  }

  .blade-bottom-margin {
    @apply mb-8 md:mb-10 lg:mb-12  xl:mb-16 2xl:mb-20;
  }
  .blade-bottom-margin-sm {
    @apply mb-4  md:mb-6  lg:mb-8 xl:mb-10 2xl:mb-14;
  }
  .blade-bottom-margin-lg {
    @apply mb-10 md:mb-12  lg:mb-16 xl:mb-20 2xl:mb-24;
  }

  .w-container-sm {
    @apply max-w-screen-2xl mx-auto  md:w-10/12 xl:w-9/12  px-3;
  }
  .w-container {
    @apply max-w-screen-2xl mx-auto md:w-11/12 lg:w-10/12 px-3;
  }
  .w-container-lg {
    @apply md:w-11/12 px-3 max-w-screen-2xl mx-auto;
  }
  .w-container-xl {
    @apply md:w-11/12 px-3 2xl:px-12 max-w-[1920px] mx-auto;
  }
}

@layer base {
  h1 {
    @apply text-2xl lg:text-3xl xl:text-[44px] 2xl:text-6xl md:leading-snug lg:leading-snug 2xl:leading-snug;
  }
  h2 {
    @apply text-3xl lg:text-4xl 2xl:text-5xl  md:leading-snug lg:leading-snug 2xl:leading-snug;
  }
  h3 {
    @apply text-2xl lg:text-3xl 2xl:text-4xl md:leading-snug lg:leading-snug 2xl:leading-snug;
  }
  h4 {
    @apply text-xl lg:text-2xl 2xl:text-3xl leading-tight md:leading-snug lg:leading-snug 2xl:leading-snug;
  }
  h5 {
    @apply text-lg lg:text-2xl 2xl:text-[28px] leading-normal md:leading-normal lg:leading-normal 2xl:leading-normal;
  }
  h6 {
    @apply text-base  lg:text-lg 2xl:text-xl leading-normal md:leading-normal lg:leading-normal 2xl:leading-normal;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.border-green-gradient {
  position: relative;
  background: white;
  border-radius: 6px;
  /* z-index: 10; */
}
.border-green-gradient::before {
  content: '';
  position: absolute;
  inset: 0px;
  margin: auto;
  border-radius: 6px;
  z-index: -1;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  background: linear-gradient(120deg, #85db18, #000);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #040418;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #040418;
}

::-webkit-scrollbar-track {
  background-color: #d8d8d8;
}

::-webkit-scrollbar-track:hover {
  background-color: #d8d8d8;
}

/* remove blue active state  for mobile phones*/
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*  text-gradients */
.gradient-black-green {
  background-image: linear-gradient(160deg, #000 0%, #85db18 60%);
}
.gradient-darkGreen-green {
  background-image: linear-gradient(to bottom, #85db18 0%, #6fc007 100%);
}
.gradient-lightGreen-green {
  background-image: linear-gradient(to bottom, #ddffb1 0%, #85db18 100%);
}
.gradient-lightGreen-green-right {
  background-image: linear-gradient(to right, #ffffff, #bfef00);
}
.footer-gradient {
  background-image: radial-gradient(
    ellipse at center bottom,
    rgba(255, 255, 255, 0.1) 5%,
    transparent
  );
}

.banner-gradient {
  background: linear-gradient(
    to bottom,
    rgb(255 255 255 / 86%) 8%,
    transparent 26%,
    transparent 20%
  );
}

.banner-gradient-nav {
  background: linear-gradient(
    to bottom,
    rgb(255 255 255 / 90%) 20%,
    transparent 90%
  );
}

.gradient-text {
  background: linear-gradient(180deg, #fff -54.83%, #85db18 111.61%);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.marquee {
  --translate-3d-x: -5%;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.marquee::before {
  display: block;
  white-space: pre;
  content: attr(data-text) '    ' attr(data-text) '    ' attr(data-text) '    '
    attr(data-text) '    ' attr(data-text) '    ' attr(data-text) '    '
    attr(data-text) '    ' attr(data-text) '    ' attr(data-text) '    '
    attr(data-text) ' ' attr(data-text) '    ' attr(data-text) '    '
    attr(data-text) '    ' attr(data-text) '    ' attr(data-text) '    '
    attr(data-text) '    ' attr(data-text) ' ' attr(data-text) '    '
    attr(data-text) '    ' attr(data-text);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: inherit;
  transform: translate3d(var(--translate-3d-x, -2%), 0, 0);
  will-change: transform;
}
.marquee::before {
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--translate-3d-x, -2%), 0, 0);
  }
  100% {
    transform: translate3d(calc(var(--translate-3d-x) - 5% - 1px), 0, 0);
  }
}

.grid-lines {
  background-image: linear-gradient(
    to right,
    rgba(82, 82, 82, 0.13),
    rgb(0 0 0 / 0%) 1px,
    transparent 1px
  );
  background-repeat: repeat-x;
  background-size: 240px 100%;
}

.blur-gradient::before {
  content: '';
  height: 30%;
  position: absolute;
  /* filter: blur(10px); */
  bottom: auto;
  top: 0;
  left: 0;
  right: 0;

  background-image: radial-gradient(
    80% 400px at center top,
    rgba(255, 255, 255, 0.07),
    transparent
  );
}
.swiper-slide {
  transition-property: transform, opacity !important;
  transition-duration: 300ms !important;
}

.text {
  color: #c0ccaf;
}

.image-active {
  opacity: 1;
  color: #7dcc15;
}

.bolt-carousel .swiper-slide {
  transition-duration: 300ms !important;
  height: auto !important;
}
.bolt-carousel .slideNextClass,
.home-carousel .slideNextClass,
.home-carousel .slidePrevClass,
.bolt-carousel .slidePrevClass {
  opacity: 0.5;
}

.home-carousel .slideNextClass,
.home-carousel .slidePrevClass {
  transform: scale(0.95);
}

.privacy-page-wrapper,
.cookie-page-wrapper,
.privacy-policy-page-wrapper {
  background-image: linear-gradient(
      145deg,
      #fff 10%,
      #ffffff6e 20%,
      transparent 40%,
      transparent 60%,
      #ffffff6e 70%,
      #fff 90%
    ),
    linear-gradient(to bottom, #cacaca, #cacaca);
}
