.choose-preference-wrapper {
  background: linear-gradient(to bottom, #fdfbfb24 0.2%, #000 67%);
}

.mink-banner-text-enter {
  opacity: 0;
  transform: translateY(10px);
}

.mink-banner-text-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 800ms, transform 800ms;
}

.mink-banner-text-exit {
  opacity: 1;
  transform: translateY(0px);
}

.mink-banner-text-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 800ms, transform 800ms;
}

.faq-wrapper {
  background: url('assets/mink/grey-gradient.webp') no-repeat;
  background-size: cover;
  background-position: bottom;
}

.specifications-wrapper {
  background-image: linear-gradient(145deg,
      #fff 15%,
      #ffffff6e 30%,
      transparent 40%,
      transparent 50%,
      #ffffff6e 60%,
      #fff 85%),
    linear-gradient(to bottom, #cacaca, #cacaca);
}

.numberButtons:nth-child(1) {
  left: 36%;
  top: 23%;
}

.numberButtons:nth-child(2) {
  left: 70%;
  top: 1%;
}

.numberButtons:nth-child(3) {
  left: 10%;
  top: auto;
  bottom: 20%;
}

.numberButtons:nth-child(4) {
  left: 76%;
  top: 32%;
}

.numberButtons:nth-child(5) {
  left: 75%;
  top: 14%;
}

.numberButtons:nth-child(6) {
  left: 45%;
  top: auto;
  bottom: 35%;
}

.numberButtons:nth-child(7) {
  left: 75%;
  top: auto;
  bottom: 14%;
}

.numberButtons:nth-child(8) {
  left: 80%;
  top: auto;
  bottom: 48%;
}

.arrow-btn-gradient {
  background: linear-gradient(to bottom, #7e7d7d 1%, #fff);
}

.arrow-btn-gradient-dark {
  background: linear-gradient(to right, #0000002b, #000);
}

.arrow-btn-gradient-dark-reverse {
  background: linear-gradient(to right, #000, #0000002b);
}

.ride-with-confidence-wrapper {
  background: url('assets/mink/grey-gradient.webp') no-repeat;
  background-size: cover;
  background-position: bottom;
}

.ride-with-confidence-wrapper-active-slide .card {
  opacity: 1;
}

.bike-wrapper {
  background: url('assets/mink/ride-with-confidence/grid.svg') no-repeat;
  background-position: bottom;
  background-size: 100%;
}

.accessories-card {
  background: linear-gradient(to bottom, #bebebe 30%, white);
}

.image-active {
  opacity: 1;
}

.image-one {
  opacity: 0;
  transition: 2s all ease-in-out;
}

.image-two {
  opacity: 0;
  transition: 2s all ease-in-out;
}

@media (max-width: 1536px) {
  .numberButtons:nth-child(7) {
    left: 75%;
    top: auto;
    bottom: 15%;
  }
}

@media (max-width: 1024px) {
  .choosePreferenceWrapper {
    background: linear-gradient(to bottom, #fdfbfb36 0.2%, #000 67%);
  }
}

@media (max-width: 1024px) {
  .mink-banner {
    background: linear-gradient(to bottom,
        rgb(255 255 255 / 86%) 8%,
        transparent 30%,
        #000 130%);
  }
}

@media (max-width: 768px) {
  .mink-banner {
    background: linear-gradient(to bottom,
        rgb(255 255 255 / 86%) 8%,
        transparent 30%,
        #000 120%);
  }
}

/* 
@media screen and (min-width:1536px) {
  .feature-wrapper {
    style={{
      grid-templa '200px 10px 200px 10px 300px 10px 200px',
    }}
  }
  
} */