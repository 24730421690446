.bolt-page .bolt-specifications-wrapper {
  background-image: radial-gradient(60% 35% at center top,
      rgba(255, 255, 255, 0.103),
      black),
    linear-gradient(to bottom, black, black);
}

.bolt-page .divider-green {
  background-image: radial-gradient(circle at center,
      white,
      #6cc100,
      transparent 80%);
}

.bolt-page .bolt-faq-bg {
  background-image: linear-gradient(145deg,
      #fff 10%,
      #ffffff6e 20%,
      transparent 40%,
      transparent 60%,
      #ffffff6e 70%,
      #fff 90%),
    linear-gradient(to bottom, #cacaca, #cacaca);
}

.bolt-page .safety-section-wrapper {
  background-image: linear-gradient(145deg,
      #bebebe 20%,
      #fcfcfc 35%,
      #fcfcfc 55%,
      #bebebe 70%);
}

.bolt-page .potential-wrapper {
  background-image: linear-gradient(145deg,
      #fcfcfc 10%,
      #bebebe 40%,
      #bebebe 10%,
      #fcfcfc 80%);
}

@media (max-width: 766px) {
  .potential-wrapper .potential-pointers-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 767px) {
  .potential-wrapper .potential-pointers-wrapper>* {
    grid-column: span 2;
  }
}

@media (min-width: 767px) and (max-width: 1023px) {
  .potential-wrapper .potential-pointers-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }

  .potential-wrapper .potential-pointers-wrapper>*:last-child:nth-child(odd) {
    grid-column-end: 4;
  }
}

@media (min-width: 1024px) {
  .potential-wrapper .potential-pointers-wrapper {
    grid-template-columns: repeat(6, 1fr);
  }

  .potential-wrapper .potential-pointers-wrapper>*:last-child:nth-child(3n-2) {
    grid-column-end: 5;
  }

  .potential-wrapper .potential-pointers-wrapper>*:last-child:nth-child(3n-1) {
    grid-column-end: 6;
  }

  .potential-wrapper .potential-pointers-wrapper>*:nth-last-child(2):nth-child(3n + 1) {
    grid-column-end: 4;
  }

  .potential-wrapper .potential-pointers-wrapper>*:nth-last-child(2):nth-child(3n + 1) .separator {
    grid-column-end: 4;
  }
}

.potential-wrapper .vehicle-view-wrapper {
  background-size: min(80%, 700px) auto;
  background-position: center top 20px;
  background-image: url('../../assets/bolt/bolt_text_gradient.svg');
}

.bolt-page .bolt-carousel {
  background-image: radial-gradient(circle at 20% 50%,
      rgba(255, 255, 255, 0.15),
      transparent 35%),
    linear-gradient(to right,
      rgba(82, 82, 82, 0.13),
      rgb(0 0 0 / 0%) 1px,
      transparent 1px);
  background-position: 20% 50%, left top;
  background-repeat: no-repeat;
  background-size: cover, 240px 100%;
}

.bolt-carousel .swiper-slide {
  transition-duration: 300ms !important;
  height: auto !important;
}

.bolt-carousel .slideNextClass,
.bolt-carousel .slidePrevClass {
  opacity: 0.5;
}

.bolt-carousel .btn-wrapper {
  width: calc(100vw);
}

@media screen and (min-width: 480px) {
  .bolt-carousel .btn-wrapper {
    width: calc(100vw / 1.1);
  }
}

@media screen and (min-width: 960px) {
  .bolt-carousel .btn-wrapper {
    width: calc(100vw / 1.2);
  }
}

@media screen and (min-width: 1280px) {
  .bolt-carousel .btn-wrapper {
    width: calc(100vw / 1.4);
  }
}

@media screen and (min-width: 1536px) {
  .bolt-carousel .btn-wrapper {
    width: calc(100vw / 1.6);
  }
}


.safety-active article:nth-child(1) {
  animation: slidefromrightside 700ms ease-in-out 0ms forwards;
}

.safety-active article:nth-child(2) {
  animation: slidefromrightside 700ms ease-in-out 100ms forwards;
}

.safety-active article:nth-child(3) {
  animation: slidefromleftside 700ms ease-in-out 100ms forwards;
}

.safety-active article:nth-child(4) {
  animation: slidefromleftside 700ms ease-in-out 100ms forwards;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(16px);
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes appear2 {
  from {
    opacity: 0;
    transform: translateY(40px);
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slidefromleftside {
  from {
    opacity: 0;
    transform: translateX(-16px);
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slidefromrightside {
  from {
    opacity: 0;
    transform: translateX(16px);
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}