.Homebanner-swiper .swiper-button-prev,
.swiper-button-next {
  color: black;
}

@media (max-width: 500px) {

  .Homebanner-swiper .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 20px !important;
  }
}

.about-wrapper {
  background: url('assets/mink/homepage_whiteblur.webp') no-repeat;
  background-position: center;
  background-size: cover;
}

.partners-wrapper {
  background: url('assets/mink/grey-gradient.webp') no-repeat;
  background-position: center;
  background-size: cover;
}

.certificates-wrapper {
  background: url('assets/home/certificates/bg-grid.svg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.mobile-screen-wrapper {
  background: url('assets/home/journey/bg.webp');
  background-position: center;
  background-size: cover;
}

.tabs-gradient {
  background: linear-gradient(129deg,
      rgba(255, 255, 255, 0.8) 31.84%,
      #e7ffc7 92.34%);
  backdrop-filter: blur(7.966722011566162px);
}

.mobile-tabs:nth-child(1) {
  width: 260px;
  top: 20%;
  left: -50%;
  position: absolute;
}

.mobile-tabs:nth-child(2) {
  width: 340px;
  right: -70%;
  left: auto;
  top: 30%;
  position: absolute;
}

.mobile-tabs:nth-child(3) {
  width: 300px;
  top: 46%;
  left: -68%;
  position: absolute;
}

.mobile-tabs:nth-child(4) {
  width: 240px;
  top: 72%;
  right: -40%;
  left: auto;
  position: absolute;
}

.partners-wrapper-marquee {
  animation-name: scroll-x;
  animation-duration: 15s;
  animation-timing-function: linear;
  animation-fill-mode: alternate;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll-x;
  -webkit-animation-duration: 15s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: alternate;
  -webkit-animation-fill-mode: alternate;
  -webkit-animation-iteration-count: infinite;
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-20% - 5rem));
  }
}

.card-child {
  background: linear-gradient(118deg, rgba(255, 255, 255, 1) 0%, #f2ffe1 85.71%),
    linear-gradient(to right, white, white);
  background-size: 2000% 2000%, 200% 200%;
}

.card-child:hover {
  background-size: 200%, 150%, 100% 100%;
}

.swiper-pagination-bullet {
  background-color: white !important;
}

.swiper-pagination-bullet-active {
  background-color: #6fc007 !important;
}

.textslide {
  position: absolute;
  left: 15px;
  right: 15px;
}

.textslide-enter {
  opacity: 0;
  transform: translateY(10px);
}

.textslide-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 800ms, transform 800ms;
}

.textslide-exit {
  opacity: 1;
  transform: translateY(0);
}

.textslide-exit-active {
  opacity: 0;
  transform: translateY(-10px);

  transition: opacity 800ms, transform 800ms;
}

@media (max-width: 1023px) {
  .mobile-tabs:nth-child(1) {
    max-width: 260px;
    top: 28%;
    left: -20%;
  }

  .mobile-tabs:nth-child(2) {
    max-width: 260px;
    left: 60%;
    top: 45%;
    right: -60%;
  }

  .mobile-tabs:nth-child(3) {
    max-width: 260px;
    top: 62%;
    left: -20%;
  }

  .mobile-tabs:nth-child(4) {
    max-width: 260px;
    top: 79%;
    right: 0%;
    left: 60%;
    position: absolute;
  }
}

@media (max-width: 640px) {
  .mobile-tabs:nth-child(1) {
    max-width: 150px;
    top: 46%;
    left: 0%;
  }

  .mobile-tabs:nth-child(2) {
    max-width: 150px;
    left: 60%;
    top: 42%;
  }

  .mobile-tabs:nth-child(3) {
    max-width: 150px;
    top: 76%;
    left: 0%;
  }

  .mobile-tabs:nth-child(4) {
    max-width: 150px;
    top: 73%;
    right: 0%;
    /* left: 0; */
    position: absolute;
  }
}

@media (max-width: 767px) {
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(calc(-25% - 3rem));
    }
  }

  .swiper-pagination-bullet {
    width: 8px !important;
    height: 8px !important;
  }
}

@media (max-width: 400px) {
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(calc(-35% - 5rem));
    }
  }

  .mobile-tabs:nth-child(2) {
    left: 60%;
  }

  .mobile-tabs:nth-child(4) {
    left: 60%;
  }
}